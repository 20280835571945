import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Modal,
  Button,
  Alert,
  Card,
  Spinner,
  Row,
  InputGroup,
  Form,
} from "react-bootstrap";
import {
  getMoveableGroups,
  moveRequest,
  createGroup,
} from "../../services/api.js";
const MoveModal = ({ show, onHide, request, currentGroupId }) => {
  const navigate = useNavigate();

  // Step 1: Select Waitlist / Group
  // Step 2: Confirm Move
  // Step 3: Create New Draft
  const [modalStep, setModalStep] = useState("1");
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [drafts, setDrafts] = useState([]);
  const [published, setPublished] = useState([]);
  const [newWaitlistName, setNewWaitlistName] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const groups = await getMoveableGroups();

        const drafts = groups.filter(
          (group) => group.status === "DRAFT" && group._id !== currentGroupId,
        );
        const published = groups.filter(
          (group) =>
            group.status === "OPEN_FOR_ENROLLMENT" &&
            group._id !== currentGroupId,
        );

        setDrafts(drafts);
        setPublished(published);
      } catch (error) {
        console.error("Error fetching data: ", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [currentGroupId]);

  const handleButtonClick = (groupId, isDraft) => {
    setSelectedGroupId(groupId);
    setModalStep("2");
  };

  const handleBackButton = () => {
    setSelectedGroupId(null);
    setModalStep("1");
  };

  const handleMoveButton = async () => {
    setIsProcessing(true);

    try {
      await moveRequest(
        request._id,
        selectedGroupId,
      );
      setIsProcessing(false);
      navigate(`/admin/groups/${currentGroupId}`);
    } catch (error) {
      setError("Something went wrong. Please try again later.");
      setIsProcessing(false);
    }
  };

  const handleCreateButton = async () => {
    setIsProcessing(true);

    try {
      const group = {
        name: newWaitlistName,
      };
      const response = await createGroup(group);

      await moveRequest(
        request._id,
        response.groupId,
      );

      navigate(`/admin/groups/${response.groupId}`);
    } catch (error) {
      setError(error.message);
    }
  };

  const renderDrafts = () => {
    return drafts.map((draft) => (
      <div className="d-flex w-100 mt-2" key={draft._id}>
        <Button
          variant="outline-dark"
          className="w-100"
          onClick={() => handleButtonClick(draft._id, true)}
        >
          <strong>Add to Waitlist:</strong> {draft.name}
        </Button>
      </div>
    ));
  };

  const renderPublished = () => {
    return published.map((publish) => (
      <div className="d-flex w-100 mt-2" key={publish._id}>
        <Button
          variant="outline-dark"
          className="w-100"
          onClick={() => handleButtonClick(publish._id, false)}
        >
          <strong>Move to Group:</strong> {publish.name}
        </Button>
      </div>
    ));
  };

  const handleCreateDraftButton = () => {
    setModalStep("3");
  };

  if (modalStep === "1" && !isLoading) {
    return (
      <Modal
        show={show}
        onHide={onHide}
        fullscreen="sm-down"
        animation={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Waitlist or Move: {request.firstName} {request.lastName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card style={{ borderColor: "var(--bs-dark)" }}>
            <Card.Body>
              {drafts.length === 0 && published.length === 0 ? (
                <p>
                  Only groups in 'Draft' or 'Open For Enrollment' statuses are
                  eligible for moves, and currently you do not have any. You
                  have the option to create a new 'Draft' and use it as a
                  waitlist.
                </p>
              ) : (
                <p>
                  You can always create a new draft group and treat it as a
                  waitlist
                </p>
              )}
              <div
                className="d-flex"
                style={{ justifyContent: "flex-end" }}
                onClick={handleCreateDraftButton}
              >
                <Button variant="dark">Create a New Draft</Button>
              </div>
            </Card.Body>
          </Card>
          {renderDrafts()}
          {renderPublished()}
          {error && (
            <Alert className="mt-2" variant="danger">
              {error}
            </Alert>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  } else if (modalStep === "2") {
    return (
      <Modal
        show={show}
        onHide={onHide}
        fullscreen="sm-down"
        animation={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Waitlist or Move: {request.firstName} {request.lastName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to move this request?
          {error && (
            <Alert className="mt-2" variant="danger">
              {error}
            </Alert>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-dark" onClick={handleBackButton}>
            Back
          </Button>
          <Button variant="dark" onClick={handleMoveButton}>
            {isProcessing ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                {" Moving"}
              </>
            ) : (
              "Move"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  } else if (modalStep === "3") {
    return (
      <Modal
        show={show}
        onHide={onHide}
        fullscreen="sm-down"
        animation={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Create a New Waitlist</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <p>
              Please enter a name for your new waitlist. Don't worry, you can
              always change it later.
            </p>
          </Row>
          <Row xs={1}>
            <InputGroup className="mb-3">
              <InputGroup.Text>Waitlist Name</InputGroup.Text>
              <Form.Control
                value={newWaitlistName}
                onChange={(e) => setNewWaitlistName(e.currentTarget.value)}
              />
            </InputGroup>
            {error && (
              <Alert className="mt-2" variant="danger">
                {error}
              </Alert>
            )}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="secondary-button"
            onClick={onHide}
            disabled={isProcessing}
          >
            Cancel
          </Button>
          <Button
            className="primary-button"
            onClick={handleCreateButton}
            disabled={isProcessing}
          >
            {isProcessing && (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />{" "}
              </>
            )}
            Create
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
};

export default MoveModal;
