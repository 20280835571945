import {
  Container,
  Button,
  Modal,
  Spinner,
  Dropdown,
  DropdownButton,
  Alert,
  Row,
  Col,
  Badge,
} from "react-bootstrap";
import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import MoveModal from "./MoveModal.js";
import {
  approveRequest,
  rejectRequest,
  sendAvailabilityCalendar,
} from "../../services/api.js";
import {
  formatTimeFromDateToHMMaa,
  formatDateToAmerican,
} from "../../utils/utils.js";

const RequestDetail = ({
  selectedRequest,
  selectedGroup,
  therapist,
  timeSlot,
  questionnaire,
}) => {
  const navigate = useNavigate();
  const [isProcessing, setIsProcessing] = useState(false);
  const [showConsultationModal, setShowConsultationModal] = useState(false);
  const [showApprovalModal, setShowApprovalModal] = useState(false);
  const [showRejectionModal, setShowRejectionModal] = useState(false);
  const [showMoveModal, setShowMoveModal] = useState(false);

  const [showActionButton, setShowActionButton] = useState(false);
  const [subStatus, setSubStatus] = useState("");
  const [subStatusClass, setSubStatusClass] = useState("warning");
  const [canMove, setCanMove] = useState(false);
  const [canDecide, setCanDecide] = useState(false);
  const [canScheduleConsultation, setCanScheduleConsultation] = useState(false);
  const [canResendConsultation, setCanResendConsultation] = useState(false);
  const [isManuallyCreated, setIsManuallyCreated] = useState(false);
  const [consultationMessage, setConsultationMessage] = useState("");

  useEffect(() => {
    if (!selectedRequest) return;

    const newState = {
      showActionButton: false,
      showConsultationInfo: false,
      subStatus: "",
      subStatusClass: "white",
      canMove: false,
      canDecide: false,
      canScheduleConsultation: false,
      canResendConsultation: false,
      isManuallyCreated: false,
      consultationMessage: "",
    };

    const formatDateTime = (date) => {
      return `${formatDateToAmerican(date)} at ${formatTimeFromDateToHMMaa(date)}`;
    };

    switch (selectedRequest.status) {
      case "PENDING":
        newState.showActionButton = true;
        newState.canMove = true;
        newState.canDecide = true;
        switch (selectedRequest.subStatus) {
          case "AWAITING_THERAPIST_RESPONSE":
            newState.canScheduleConsultation = true;
            newState.subStatusClass = "dark";
            newState.subStatus = "AWAITING YOUR RESPONSE";
            break;
          case "AWAITING_THERAPIST_DECISION":
            newState.subStatusClass = "secondary";
            newState.subStatus = "AWAITING YOUR DECISION";
            newState.consultationMessage = `Consultation session was scheduled for ${formatDateTime(timeSlot.startTime)}`;
            break;
          case "AWAITING_CLIENT_RESPONSE":
            newState.canResendConsultation = true;
            newState.subStatusClass = "warning";
            newState.subStatus = "AWAITING CLIENT RESPONSE";
            if (selectedRequest.availabilityCalendarLastSent) {
              newState.consultationMessage = `Sent consultation availability calendar to the client on ${formatDateTime(selectedRequest.availabilityCalendarLastSent)}`;
            } else {
              newState.consultationMessage =
                "Availability calendar has not been sent yet.";
            }
            break;
          case "CONSULTATION_SCHEDULED":
            newState.subStatusClass = "success";
            newState.subStatus = "CONSULTATION SCHEDULED";
            newState.consultationMessage = `Consultation session scheduled for ${formatDateTime(timeSlot.startTime)}`;
            break;
          default:
            newState.subStatusClass = "white";
            newState.subStatus = "N/A";
        }
        break;
      case "APPROVED":
        newState.showActionButton = true;
        newState.canMove = true;
        newState.isManuallyCreated = !selectedRequest.birthDate;
        newState.subStatusClass = "success";
        newState.subStatus = "APPROVED";
        break;
      case "REJECTED":
        newState.subStatusClass = "danger";
        newState.subStatus = "REJECTED";
        break;
      case "WAITLISTED":
        newState.showActionButton = true;
        newState.canMove = true;
        newState.subStatusClass = "secondary";
        newState.subStatus = "WAITLISTED";
        break;
      default:
        newState.subStatusClass = "white";
        newState.subStatus = "N/A";
    }

    setShowActionButton(newState.showActionButton);
    setCanScheduleConsultation(newState.canScheduleConsultation);
    setCanResendConsultation(newState.canResendConsultation);
    setCanMove(newState.canMove);
    setCanDecide(newState.canDecide);
    setSubStatusClass(newState.subStatusClass);
    setSubStatus(newState.subStatus);
    setIsManuallyCreated(newState.isManuallyCreated);
    setConsultationMessage(newState.consultationMessage);
  }, [selectedRequest, timeSlot]);

  const getStatusMessage = (status, updatedAt) => {
    const formattedDate = formatDateToAmerican(updatedAt);
    const formattedTime = formatTimeFromDateToHMMaa(updatedAt);

    switch (status) {
      case "APPROVED":
        return `You approved this request on ${formattedDate} ${formattedTime}`;
      case "REJECTED":
        return `You rejected this request on ${formattedDate} ${formattedTime}`;
      default:
        return `You added this member to the group manually on ${formattedDate} ${formattedTime}`;
    }
  };

  const renderAnswers = () => {
    return questionnaire.map((item) => {
      return (
        <div key={item.questionText}>
          <h6 className="mt-3" style={{ marginBottom: "0px" }}>
            {item.questionText}
          </h6>
          <p className="text-secondary">{item.answer}</p>
        </div>
      );
    });
  };

  const handleBackButton = () => {
    navigate(`/admin/groups/${selectedGroup._id}`);
  };

  /* WAITLIST / MOVE */

  const handleMoveButton = () => {
    setShowMoveModal(true);
  };

  const handleCloseMoveModal = () => {
    setShowMoveModal(false);
  };

  /* CONSULTATION */

  const handleScheduleButton = () => {
    setShowConsultationModal(true);
  };

  const handleCloseConsultationModal = () => {
    setShowConsultationModal(false);
  };

  const handleSendAvailabilityButton = async () => {
    setIsProcessing(true);
    try {
      await sendAvailabilityCalendar(selectedRequest._id);
    } catch (error) {
      console.log("Error sending availability calendar to the client");
    }
    navigate(0);
  };

  const handleSaveAvailabilityButton = async () => {
    setShowConsultationModal(false);
  };

  /* APPROVAL */

  const handleApproveButton = () => {
    setShowApprovalModal(true);
  };

  const handleCloseApprovalModal = () => {
    setShowApprovalModal(false);
  };

  const handleApproval = async () => {
    setIsProcessing(true);
    try {
      await approveRequest(selectedRequest._id);
      navigate(
        `/admin/groups/${selectedGroup._id}/request/${selectedRequest._id}`,
      );
    } catch {
      console.log("Error updating request status");
    }
    setIsProcessing(false);
    setShowApprovalModal(false);
  };

  /* REJECTION */

  const handleRejectButton = () => {
    setShowRejectionModal(true);
  };

  const handleCloseRejectionModal = () => {
    setShowRejectionModal(false);
  };

  const handleRejection = async () => {
    setIsProcessing(true);
    let newRequestId = selectedRequest._id;
    try {
      const response = await rejectRequest(selectedRequest._id);
      newRequestId = response.newRequestId;
      navigate(`/admin/groups/${selectedGroup._id}/request/${newRequestId}`);
    } catch {
      console.log("Error updating request status");
    }
    setIsProcessing(false);
    setShowRejectionModal(false);
  };

  return (
    <>
      <Container className="settings-header-container" fluid>
        <Row
          className="d-flex justify-content-between align-items-center px-4"
          style={{ height: "7rem" }}
        >
          <Col xs={8}>
            <div className="d-flex">
              <h4 style={{ marginBottom: "0px" }}>
                {selectedRequest.firstName} {selectedRequest.lastName}
              </h4>
              <Badge
                bg={`${subStatusClass}`}
                style={{
                  marginTop: "auto",
                  marginBottom: "auto",
                  height: "20px",
                  marginLeft: "10px",
                }}
              >
                {subStatus}
              </Badge>
            </div>
            <p style={{ marginBottom: "0px" }} className="text-secondary">
              {selectedRequest.emailAddress}
            </p>
            <p style={{ marginBottom: "0px" }} className="text-secondary">
              {formatPhoneNumberIntl(selectedRequest.phoneNumber)}
            </p>
          </Col>
          <Col
            xs={4}
            className="d-flex align-items-center justify-content-end"
            style={{ paddingRight: "0" }}
          >
            <Button
              className="secondary-button pill-button"
              onClick={handleBackButton}
            >
              Back
            </Button>
            {showActionButton && (
              <DropdownButton
                id="request-action-dropdown"
                variant="dark"
                title="Actions"
              >
                {canMove && (
                  <Dropdown.Item onClick={handleMoveButton}>
                    Move to another group or waitlist
                  </Dropdown.Item>
                )}
                {canScheduleConsultation && (
                  <Dropdown.Item onClick={handleScheduleButton}>
                    Invite for a 15-min Consultation
                  </Dropdown.Item>
                )}
                {canResendConsultation && (
                  <Dropdown.Item onClick={handleScheduleButton}>
                    Resend the invitation link
                  </Dropdown.Item>
                )}
                {canDecide && (
                  <>
                    <Dropdown.Divider />
                    <Dropdown.Item
                      onClick={handleApproveButton}
                      style={{ fontWeight: "500", color: "var(--bs-success)" }}
                    >
                      Approve Request
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={handleRejectButton}
                      style={{ fontWeight: "500", color: "var(--bs-danger)" }}
                    >
                      Reject Request
                    </Dropdown.Item>
                  </>
                )}
              </DropdownButton>
            )}
          </Col>
        </Row>
        <hr style={{ marginTop: "0px" }} />
      </Container>
      <Container className="main-container">
        {therapist.timeZone !== selectedRequest.timeZone && (
          <div>
            <h6 className="mt-3" style={{ marginBottom: "0px" }}>
              Client Time Zone
            </h6>
            <p className="text-secondary">{selectedRequest.timeZone}</p>
          </div>
        )}
        {isManuallyCreated ? (
          <div>
            <h6 className="mt-5">
              {getStatusMessage("MANUALLY_CREATED", selectedRequest.updatedAt)}
            </h6>
          </div>
        ) : (
          <>
            <div>
              <h6 className="mt-3" style={{ marginBottom: "0px" }}>
                Client Date of Birth
              </h6>
              <p className="text-secondary">{selectedRequest.birthDate}</p>
            </div>
            {renderAnswers()}
            {["APPROVED", "REJECTED"].includes(selectedRequest.status) && (
              <div>
                <h6 className="mt-5">
                  {getStatusMessage(
                    selectedRequest.status,
                    selectedRequest.updatedAt,
                  )}
                </h6>
              </div>
            )}
          </>
        )}

        {selectedRequest.status === "PENDING" && (
          <div className="mt-5">
            {consultationMessage && (
              <Alert variant="light">{consultationMessage}</Alert>
            )}
          </div>
        )}
      </Container>
      {showMoveModal && (
        <MoveModal
          show={showMoveModal}
          onHide={handleCloseMoveModal}
          request={selectedRequest}
          currentGroupId={selectedGroup._id}
        />
      )}
      {showApprovalModal && (
        <Modal show={showApprovalModal} onHide={handleCloseApprovalModal}>
          <Modal.Header closeButton>
            <Modal.Title>Approve Request</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to approve this request?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseApprovalModal}>
              Cancel
            </Button>
            <Button variant="success" onClick={handleApproval}>
              {isProcessing ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  {" Approving"}
                </>
              ) : (
                "Approve"
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {showRejectionModal && (
        <Modal show={showRejectionModal} onHide={handleCloseRejectionModal}>
          <Modal.Header closeButton>
            <Modal.Title>Reject Request</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to reject this request?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseRejectionModal}>
              Cancel
            </Button>
            <Button variant="danger" onClick={handleRejection}>
              {isProcessing ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  {" Rejecting"}
                </>
              ) : (
                "Reject"
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {showConsultationModal &&
        (therapist.availability ? (
          <Modal
            show={showConsultationModal}
            onHide={handleCloseConsultationModal}
          >
            <Modal.Header closeButton>
              <Modal.Title>Send Consultation Invite</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              When you send the client a consultation invitation, they will
              receive a unique link, using which they will be able to view your
              availability calendar and book one of your available 15-min time
              slots.
              <br />
              <br />
              Are you sure you want to continue with sending a consultation
              invite to this client?
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={handleCloseConsultationModal}
              >
                Cancel
              </Button>
              <Button variant="dark" onClick={handleSendAvailabilityButton}>
                {isProcessing ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    {" Sending"}
                  </>
                ) : (
                  "Send"
                )}
              </Button>
            </Modal.Footer>
          </Modal>
        ) : (
          <Modal
            show={showConsultationModal}
            onHide={handleCloseConsultationModal}
          >
            <Modal.Header closeButton>
              <Modal.Title>Set Availability</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              You need to set up your 15-min consultation session availability
              calendar before sending an invitation link to the client.
              <br />
              <br />
              You can{" "}
              <Link to="/admin/settings/availability">
                visit your setup page
              </Link>{" "}
              at any time to edit your availability.
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={handleCloseConsultationModal}
              >
                Cancel
              </Button>
              <Button variant="dark" onClick={handleSaveAvailabilityButton}>
                {isProcessing ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    {" Saving"}
                  </>
                ) : (
                  "Save"
                )}
              </Button>
            </Modal.Footer>
          </Modal>
        ))}
    </>
  );
};

export default RequestDetail;
